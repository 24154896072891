<template>
   <div class="cont">
      <!-- 导航条 -->
      <el-row>
         <el-link class="l-height-50 back_iconfont" type="primary" icon="iconfont iconfanhui1" @click="goBack()">
            <span>商品管理</span>
         </el-link>
      </el-row>
      <!-- 商品详情 -->
      <div class="det-box">
         <el-divider content-position="left"><span>商品详情信息</span></el-divider>
         <el-row class="m-bottom-15">
            <ul>
               <li>
                  <div class="box-img">
                     <!--<img v-if="goodsData.coverPath" :src="goodsData.coverPath" class="v-align-middle"/>-->
                     <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" class="v-align-middle" alt="">
                  </div>
               </li>
               <li></li>
               <li></li>
               <li></li>
               <li></li>
               <li></li>
               <li></li>
            </ul>
            <ul>
               <li>
                  <span>商品名称：</span>
                  <span>{{goodsData.title || '-'}}</span>
               </li>
               <li>
                  <span>商品价格：</span>
                  <span>{{goodsData.price || '-'}}</span>
               </li>
            </ul>
            <ul>
               <li>
                  <span>商品卖点：</span>
                  <span>{{goodsData.usp || '-'}}</span>
               </li>
               <li>
                  <span>商品库存：</span>
                  <span>{{goodsData.quantity || '-'}}</span>
               </li>
            </ul>
         </el-row>
      </div>
   </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   export default {
      data(){
         return{
            goodsData: {}
         }
      },
      mounted () {
         this.id = this.$route.query.id
         this.getDetail()
      },
      methods: {
         // 获取商品详情
         getDetail(){
            const url = urlObj.getGoodsDetails + `/${this.id}`
            this.$axios.post(url, {}, 'json').then(res => {
               if (res.success){
                  this.goodsData = res.records
               }
            }).catch(error => {
               console.log(error)
            })
         },
         goBack(){
            this.$router.go(-1)
         }
      }
   }
</script>
<style scoped lang="scss">
   .cont{
      min-width: 1120px;
   }
   .el-row{
      line-height: 30px;
      white-space: nowrap;
   }
   .det-box{
      /* border: 1px solid red; */
      background: white;
      padding: 10px 20px;
      margin-top: 10px;
      &::after{
         content: "";
         display: block;
         clear: both;
      }
      &:nth-child(2){
         margin-top: 0;
      }
      ul{
         width: 33.3%;
         float: left;
         list-style: none;
         li{
            //   float: left;
            // width: 25%;
            position: relative;
            height: 30px;
            line-height: 30px;
            margin-bottom: 20px;
            font-size: 14px;
            white-space: nowrap;
            .box-img{
               position: absolute;
               top: 0;
               left: 0;
               width: 60%;
               height: 130px;
               img{
                  width: 100%;
                  height: 100%;
               }
            }
         }
      }
   }
   .icon-list{
      width: 15%;
      display: inline-block;
   }
   p{
      white-space: pre-wrap;
   }
   .map {
      width: 100%;
      height: 400px;
   }
</style>
